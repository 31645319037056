div#arrowUp {
  position: absolute;
  z-index: 9995;
  bottom: 90px;
  right: 55px;
  text-align: center;
  a {
    height: 30px;
    width: 30px;
    display: block;
    background: url(../img/arrow-up.png) center center no-repeat;
    &:hover {
      background: url(../img/arrow-up-hover.png) center center no-repeat;
    }
  }
}

.page-content-btn {
  margin: 40px 15px 0;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  position: relative;
  color: #fff;
  font-weight: 400;
  background-color: #cdb385;
  padding: 20px 15px;
  text-transform: uppercase;
  transition: background 0.3s ease-in-out;
  span {
    font-size: 18px;
    line-height: 26px;
    width: 140px;
    height: 140px;
    padding: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -26px 0 0 -70px;
  }
  &:hover {
    background-color: #bc9f69;
    color: #fff;
  }
  &:active, &:focus, &:visited {
    background-color: #bc9f69;
    outline: 0;
    color: #fff;
  }
}

.page-content-btn-1 {
  margin: 30px 15px 0;
  padding: 7px 70px;
  position: relative;
  color: $white;
  font-weight: 400;
  background-color: #3294d1;
  transition: background 0.3s ease-in-out;
  text-transform: none;
  border-radius: 0;
  &:hover, &:active, &:focus, &:visited {
    background-color: #00305b;
    outline: 0;
    color: #fff;
  }
}

.arrow-nav {
  padding: 14px 0;
  overflow: hidden;
  a {
    font-weight: 300;
    font-size: 20px;
    color: #aaa;
    line-height: 27px;
    height: 27px;
    text-decoration: none;
    &:hover {
      color: #ed8629;
    }
  }
  .arrow-left-container {
    float: left;
    background: url(../img/arrow-prev.png) left top no-repeat;
    &:hover {
      background: url(../img/arrow-prev.png) left bottom no-repeat;
    }
    a {
      padding-left: 35px;
    }
  }
  .arrow-right-container {
    float: right;
    background: url(../img/arrow-next.png) right top no-repeat;
    &:hover {
      background: url(../img/arrow-next.png) right bottom no-repeat;
    }
    a {
      padding-right: 35px;
    }
  }
  .view-all {
    font-size: 22px;
    color: $secondary-color;
    text-align: center;
    margin-bottom: 70px;
  }
}

.arrow-down {
  position: absolute;
  bottom: 27px;
  z-index: 800;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  a {
    transition: all .2s ease;
    height: 50px;
    width: 50px;
    display: inline-block;
    background: url(../img/arrow_down.png) center center no-repeat;
    -webkit-animation: sdb05 1.5s infinite;
    animation: sdb05 1.5s infinite;
  }
  @include break(400px) {
    display: none;
  }
}

.tp-caption {
  white-space: inherit !important;
}

.tparrows {
  position: absolute;
  &.tp-leftarrow:before, &.tp-rightarrow:before {
    content: "";
  }
  @include break(400px) {
    display: none;
  }
}

.tp-leftarrow {
  z-index: 100;
  cursor: pointer;
  background: url(../img/prev.png) no-repeat center center;
  background-color: rgba(0,0,0,0.3);
  width: 40px;
  height: 40px;
  &:hover {
    background-color: rgba(0,0,0,1);
  }
}

.tp-rightarrow {
  z-index: 100;
  cursor: pointer;
  background: url(../img/next.png) no-repeat center center;
  background-color: rgba(0,0,0,0.3);
  width: 40px;
  height: 40px;
  &:hover {
    background-color: rgba(0,0,0,1);
  }
}

.title {
  margin: 0;
  font-weight: 300;
  text-align: center;
}
.box-title {
  margin:0;
}
.box1, .box2 {
  .row {
    display: flex;
    flex-wrap: wrap;
  }
}

.underline {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background: #bcd4e4;
    height: 1px;
    width: 130px;
    margin-left: -65px;
    left: 50%;
    bottom: -17.5px;
  }
}

.scroll-effect {
  background-attachment: fixed;
}

.filter-years-container {
  width: 100%;
  font-size: 30px;
  line-height: 46px;
  display: inline-block;
  text-align: center;
  font-weight: 700;
  margin-bottom: 40px;
  a {
    color: #a9b0b4;
    padding: 0 15px;
    transition: color 0.3s ease;
    &:hover, &.active {
      color: $primary-color;
    }
  }
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

@keyframes sdb05 {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translate(0, 20px);
		opacity: 0;
	}
}
