.gallery {
  margin-top: 70px;
  @include phone() {
    margin-top: 10px;
  }
  .item {
    @extend .col-md-4;
    @extend .col-sm-6;
    @extend .col-xs-6;
    margin-bottom: 40px;
    @include phone() {
      margin-bottom: 10px;
    }
    a {
      min-height: 300px;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: top center;
      display:block;

      @include break(400px) {
        min-height: 120px;
      }
    }
  }
  figcaption {
    display: none;
  }
}
