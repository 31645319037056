.slider {
  overflow: hidden;
  min-height: 1080px;
  h1 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 60px;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    margin: 0;
    @include phone() {
      font-size: 40px;
    }
  }
  h2 {
    margin: 0;

    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 60px;
    color: #fff;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    @include phone() {
      font-size: 40px;
    }
  }

  .tp-bgimg {
    &:before {
      content: '';
      background: rgba(0,0,0,0.45);
      width: 100%;
      height: 10000px;
      position: absolute;

    }
  }
}
