.fixedMenuLarge {
  position: relative;
  height: 70px;
  transition: all 0.2s ease;

  .languages-xl {
    transition: all .3s ease;
    position: absolute;
    text-align: center;
    display: inline-block;
    width: 100%;
    top: 45px;
    right: 0;
    z-index: 9999;
    text-transform: uppercase;
    @include phone() {
      top: 13px;
    }
    .separator {
      margin: 0px 15px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left:-2px;
        top:-5px;
        height: 40px;
        width: 1px;
        background: #ccc;
      }
    }
    a {
      font-size: 26px;
      color: $white;
      text-decoration: none;
      &.active, &:hover {
        color: $secondary-color;
        text-decoration: none;
      }
      @include phone() {
      font-size: 18px;
      }
    }
    span {
      color: $secondary-color;
      font-size: 26px;
      @include phone() {
        font-size: 18px;
      }
    }
  }

  nav#mainNav {
    transition: all .3s ease;
    position: absolute;
    right: 90px;
    top: 0;
    z-index: 9998;
    text-align: left;
    > ul {
      padding: 0;
      margin: 0;
      > li {
        display: inline-block;
        text-align: center;
        width: auto;
        vertical-align: top;
        position: relative;
        &:last-of-type {
          padding-right: 0;
        }
        > a {
          display: block;
          font-size: 18px;
          line-height: 70px;
          font-weight: 300;
          color: #555;
          text-transform: uppercase;
          text-decoration: none;
          padding: 0 14px;
          &.active, &:hover {
            background-color: #0e1d2e;
            color: #fff;
            text-decoration: none;
          }
        }
        &:hover .dropdown-menu {
          display: block;
          margin-top: 30px;
        }
      }
    }
    .dropdown-menu {
      padding: 0;
      margin: 0;
      box-shadow: none;
      border: 0;
      z-index: 9999;
      top: 40px;
      background-color: transparent;
      text-align: center;
      > li a {
        background-color: rgba(255, 255, 255, 0.9);
        text-align: left;
        font-size: 16px;
        line-height: 42px;
        text-transform: none;
        font-weight: 300;
        padding: 0 50px 0 17px;
        color: #0e1d2e;
        &:hover {
          color: #fff;
          background-color: rgba(#00172c, 0.95);
        }
      }
    }
  }
  .dropdown:hover .dropdown-menu {
    display: block;
  }
}

.cd-primary-nav {
  visibility: hidden;
  z-index: -9999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: background .3s ease;
  opacity: .1;
  overflow-y: auto;
  @include phone() {
    width: 100%;
  }
  &.is-visible {
    z-index: 10001;
    visibility: visible;
    opacity: 1;
    background: rgba(#001020, 0.95);
  }
}

.collapse-plus {
  cursor: pointer;
  cursor: hand;
  position: absolute;
  right: 30px;
  top: 12px;
  width: 20px;
  height: 20px;
  background: url(../img/menu-plus.png) center center no-repeat;
}

.collapse-minus {
  cursor: pointer;
  cursor: hand;
  position: absolute;
  right: 30px;
  top: 12px;
  width: 20px;
  height: 20px;
  background: url(../img/menu-minus.png) center center no-repeat;
}

ul.closed {
  display: none;
}

.cd-primary-nav {
  padding-top: 50px;
  ul.fullscreenmenu {
    margin-top: 20px;
    margin-bottom: 20px;
    li {
      position: relative;
      text-align: center;
      padding: 10px 0;
      margin-bottom: 20px;
      @include phone() {
        margin-bottom: 5px;
      }
      &:last-of-type {
        border-bottom: 0;
      }
      a {
        font-size: 18px;
        font-weight: 300;
        color:$white;
        text-align: center;
        &.active, &:hover {
          color: $secondary-color;
        }
        @include phone() {
          font-size: 28px;
          font-weight: 400;
        }
      }
      ul {
        margin-top: 5px;
        margin-bottom: 0;
        li {
          padding: 5px 0;
          border-bottom: 0;
          a {
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 300;
            color: #0e1d2e;
            text-transform: uppercase;
            &.active, &:hover {
              color: #0e1d2e;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.secondary-header-nav {
  display:inline-block;
  width:100%;
  text-align: center;
  margin-top: 40px;
  li {
    display:inline-block;
    padding: 0 20px;

    @include phone() {
      padding: 0;
      margin-right: 20px;
    }
    a {
      font-size: 25px;
      color:$white;
      font-weight: normal;
      &.active, &:hover {
        color:$secondary-color;
      }
      @include phone() {
        font-size: 16px;
      }
    }
  }
}
.navigation-menu {
  @include absolutecenter();
  @include phone() {
    width:100%;  
  }
}
