html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: $body-font, sans-serif;
  color: $dark;
  font-size: $body-fz;
  line-height: $body-lh;
}
:focus {
  outline: 0;
}

button, input, keygen, select, textarea {
  font-family: $body-font, sans-serif;
}

// .modal {
//   z-index: 99999 !important;
// }

a {
  color:$secondary-color;
  // transition: color 0.3s ease;
  &:hover {
    text-decoration: none;
    color: $primary-color;
  }
}

// .main-wrapper {
//   clear: both;
//   background: #FFF;
//   max-width: 100%;
// }
//
// .wrapper {
//   max-width: 1230px;
//   margin: 0 auto;
//   padding-left: 30px;
//   padding-right: 30px;
// }

.accents {
  margin-bottom: 45px;

  .full-width-bg-color {
    background: $primary-color;
    padding-top: 41px;
    margin-bottom: 45px;
  }
  .title {
    font-size: $accents-title-fz;
    line-height: 0.7;
    color: $white;
    font-family: "Open Sans", sans-serif;
    @include phone() {
      font-size: 30px;
    }
  }
}
.news {
  margin-bottom: 30px;
}
// .img-container {
//   position: relative;
//   overflow: hidden;
//   img {
//     transition: all .5s ease;
//     -webkit-transition: all .5s ease;
//     -moz-transition: all .5s ease;
//     -o-transition: all .5s ease;
//     -ms-transition: all 0.5s ease;
//   }
// }
.index-image {
  min-height: 550px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .content-box {
    @include absolutecenter();

    background: rgba($primary-color, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 250px;
    height: 250px;
    font-size: $content-box-fz;
    line-height: $content-box-lh;
    color: $white;
    font-weight: 300;
    text-align: center;

    b{
      display: block;
    }
  }
  @include break(400px) {
    min-height: 220px;
    .content-box {
      width: 140px;
      height: 140px;
      font-size: 22px;
      line-height: 1.2;
    }
  }
}
.index-image-2 {
  margin-bottom: 60px;
}
.breadcrumbs {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 50px;
  color: #999;
  @include phone() {
    display: none; 
  }
  a {
    color: #999;
    text-decoration: none;
    font-weight: 300;
    font-size: 16px;
    &:hover {
      color: $primary-color;
      text-decoration: none;
    }
  }
}

.cbp-popup-wrap {
  z-index: 999999;
}

.cbp-caption-fadeIn .cbp-caption-activeWrap {
  background-color: rgba(14, 29, 46, 0.5);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  width: 100%;
  span {
    font-size: 120px;
    line-height: 120px;
    color: #fff;
    font-weight: 300;
    text-align: center;
  }
}

label {
  font-size: 17px;
  font-weight: 300;
  color: #0e1d2e;
  span {
    color: #ed8629;
    font-weight: 700;
  }
}

.button {

  color: #fff;
  background-color: #0e1d2e;
  padding: 0 70px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  text-transform: uppercase;
  &:hover {
    color: #fff;
    background-color: #ff983b;
  }
}

.bannercontainer {
  width: 100% !important;
  position: relative;
  padding: 0;
}

.banner-inner {
  width: 100% !important;
  overflow: hidden !important;
  position: relative;
}
//
// #container {
//   position: relative;
//   height: 200px;
//   width: 200px;
//   overflow: hidden;
//   background-color: grey;
//   &:before {
//     content: '';
//     position: absolute;
//     left: 20%;
//     width: 100%;
//     height: 200%;
//     background-color: #fff;
//     background-color: rgba(255, 255, 255, 0.5);
//     top: 0;
//     -webkit-transform: rotate(45deg);
//     transform: rotate(45deg);
//   }
// }

.slick-dots {
  display: block;
  width: 100%;
  padding: 25px 0 0;
  list-style: none;
  text-align: center;
  button, input {
    border: none;
  }
  li {

    font-weight: 300;
    font-size: 28px;
    line-height: 36px;
    color: #0e1d2e;
    display: inline-block;
    background-color: #fff;
    padding: 0 15px;
    button {
      background-color: #fff;
      &:focus, &:hover {
        background-color: #fff;
        color: #020407;
      }
    }
    &.slick-active button {
      font-weight: 800;
      font-size: 36px;
      line-height: 36px;
      color: #0e1d2e;
      background-color: #fff;
    }
  }
}

.heading-big {
  font-size: 30px !important;
  line-height: 27px !important;
  color: #00305b;
  font-weight: 600;
}

.heading-medium {
  font-size: 24px !important;
  line-height: 27px !important;
  color: $secondary-color;
  font-weight: 600;
}

.heading-small {
  font-size: 22px !important;
  line-height: 27px !important;
  color: $secondary-color;
  font-weight: normal;
}

.editor-custom-color {
  color: $primary-color !important;
}

p + .heading-big {
  margin-top: 55px;
}
p + .heading-medium, p + .heading-small {
  margin-top: 35px;
}
.box-filter-layout {
  .box2 {
    .category-link {
      display: none;
    }
  }
}
.tp-revslider-slidesli {
  position: absolute;
}
