/* Grid. */
$grid-max-width: 1360px;

$breakpoint-phone: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

/* Colors. */
$primary-color: #00305b;
$secondary-color: #7ab5da;
$dark-bg-color: #002240;
$dark: #333c41;
$white: #ffffff;

/* Typography and colors. */
$body-font: Roboto;
$body-fz: 17px;
$body-lh: 27px;

$welcome-title-fz: 38px;
$welcome-title-lh: 50px;

$accents-title-fz: 50px;
$accents-title-lh: 60px;

$box1-title-fz: 28px;
$box1-title-lh: 34px;

$box2-title-fz: 18px;
$box2-title-lh: 24px;
$large-date-fz: 52px;
$large-date-lh: 40px;
$small-date-fz: 16px;
$caterory-link-fz: 20px;
$see-more-fz: $small-date-fz;

$content-box-fz:40px;
$content-box-lh:48px;

$page-title: 50px;

$footer-nav-fz: $small-date-fz;
$footer-adress-top-fz: 26px;
$footer-adress-fz: 20px;
