header {
  height: 70px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1400;

  .contact-us {
    position: absolute;
    top:45px;
    left:35px;
    transition: all .3s ease;

    i {
      background: url('../img/envelope.png');
      width: 70px;
      height: 30px;
      display: block;
    }

  }
  .menu-button {
    transition: all .3s ease;
    position: absolute;
    right: 35px;
    top: 35px;
    z-index: 10002;
    text-align: right;
    display: block;
    button {
      display: inline-block;
      padding: 7px 0;
      border: none;
      background: 0 0;
      right: 50px;
      span {
        display: block;
      }
    }
    .lines-button {
      transition: .3s;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:hover {
        opacity: 1;
      }
    }
    .lines {
      display: inline-block;
      width: 40px;
      height: 1px;
      background: $white;
      transition: .3s;
      position: relative;
      &:after {
        display: inline-block;
        width: 40px;
        height: 1px;
        background: $white;
        transition: .3s;
        position: absolute;
        left: 0;
        content: '';
        -webkit-transform-origin: .28571rem center;
        transform-origin: 0.28571rem center;
      }
      &:before {
        display: inline-block;
        width: 40px;
        height: 1px;
        background: $white;
        transition: .3s;
        position: absolute;
        left: 0;
        content: '';
        -webkit-transform-origin: .28571rem center;
        transform-origin: 0.28571rem center;
        top: 10px;
      }
      &:after {
        top: -10px;
      }
    }
    .lines-button {
      &:hover .lines {
        &:before {
          top: 10px;
        }
        &:after {
          top: -10px;
        }
      }
      &.close2 {
        -webkit-transform: scale3d(0.8, 0.8, 0.8);
        transform: scale3d(0.8, 0.8, 0.8);
      }
      &.x.close2 .lines {
        background: 0 0;
        &:after {
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          top: 0;
          width: 40px;
          background: $white;
        }
        &:before {
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          top: 0;
          width: 40px;
          background: $white;
          -webkit-transform: rotate3d(0, 0, 1, 45deg);
          transform: rotate3d(0, 0, 1, 45deg);
        }
        &:after {
          -webkit-transform: rotate3d(0, 0, 1, -45deg);
          transform: rotate3d(0, 0, 1, -45deg);
        }
      }
    }
  }

  .logo {
    @include absolutecenter('x');
    transition: all .3s ease;
    top: 25px;
  }
  .logo-fixed {
    display: block;
  }
  .logo-sticky {
    display: none;
  }

  &.sticky-menu {
    .fixedMenuLarge {
      background: rgba($dark-bg-color, 0.9);
      height: 50px;
    }
    .contact-us {
      top:10px;
      i {
        width: 60px;
        background-size: 100%;
      }
    }
    .menu-button {
      top:0;
      .lines {
        width: 30px;
        &:before {
          width: 30px;
          top: 8px;
        }
        &:after {
          width: 30px;
          top: -8px;
        }
      }
    }
    .lines-button {
      &:hover .lines {
        &:before {
          top: 8px;
        }
        &:after {
          top: -8px;
        }
      }
    }
    .logo-fixed {
      display: none;
    }
    .logo-sticky {
      display: block;
      top:0;
    }
  }

  @include break(767px)  {
    @include sticky();
    .contact-us {
      display: none;
    }
    .logo {
      left:0;
      top:0;
      transform:unset;
    }
  }
}
