  footer {
  position: relative;
}
.footer-nav {
  @extend .col-lg-10;
  @extend .col-lg-offset-1;

  margin-bottom: 30px;
  ul {
    padding: 0;
    margin: 0;
    li {
      display: inline;
      text-align: center;
      padding: 0 17px;
      a {
        white-space: nowrap;

        font-size: $footer-nav-fz;
        text-transform: uppercase;
        &.active, &:hover {
          color: $secondary-color;
        }
      }
    }
  }
  @include break(400px) {
    display: none;
  }
}
.footer-bottom {
  background-color: #002240;
  text-align: center;
  padding-top: 45px;
  color: $white;
  a {
    color: $white;
    &:hover {
      color: $secondary-color;
      outline: 0;
    }
  }
  p {
    margin: 0;
  }
  address {
    margin-bottom: 45px;
    p {
      font-size: $footer-adress-fz;
      font-weight: 300;
      @include phone() {
        font-size: 15px;
      }
    }
    strong {
      font-size: $footer-adress-top-fz;
    }
  }
  .followus {
    margin-bottom: 40px;
    a {
      padding: 0 15px;
      &:hover img {
        opacity: 0.65;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
    img {
      height: 25px;
    }
  }

  .copywrite {
    padding: 15px 0;
    background: #00172c;
    font-size: 15px;
    color: #a0bdd2;
  }
}
