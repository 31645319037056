.page-title {
  @extend .underline;

  font-size: $page-title;
  color: $primary-color;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;

  @extend .col-md-10;
  @extend .col-md-offset-1;
  @include phone() {
    font-size: 28px;
    padding: 0 25px;
  }
}
.page-text {
  @extend .col-md-10;
  @extend .col-md-offset-1;
  color: $primary-color;
  margin-bottom: 35px;
  text-align: center;
  font-weight: 300;

  &.text-bottom {
    margin-top: 45px;
    margin-bottom: 65px;
  }
}

.page-body {
  @extend .col-lg-12;
  font-weight: 300;
  a {
    font-weight: bold;
    text-decoration: underline;
    color: #3294d1;
    &:hover, &:focus {
      text-decoration: none;
      color: #00305b;
    }
  }
}

div {
  &.footerShareThis {
    padding-top: 45px;
    padding-bottom: 50px;
    text-align: center;
  }
  &#likeButton a {
    margin-left: 80px;
    &:first-of-type {
      margin-left: 0;
    }
  }
}

#likeButtonOpen {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(../img/fb-like.png) center center no-repeat;
  &:hover {
    background: url(../img/fb-like-hover.png) center center no-repeat;
  }
}

.shareButton {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: top;
  background: url(../img/share.png) center center no-repeat;
  &:hover {
    background: url(../img/share-hover.png) center center no-repeat;
  }
}

#likeButtonOpen img:hover, .shareButtonOpen img:hover {
  opacity: 0.6;
}

#like_butt_hold {
  overflow: hidden;
  margin-top: 20px;
  height: 25px;
  display: none;
}

.fb_iframe_widget {
  height: 20px !important;
  vertical-align: top !important;
  span {
    vertical-align: top !important;
  }
}
.subpages {
  text-align: center;
  margin-bottom: 50px;
  @include phone() {
    padding-top: 22px;
    margin-bottom: 25px;
  }
  li {
    display: inline-block;
    margin-right: 48px;
    font-size: 18px;
    @include phone() {
      margin-right: 10px;
      padding: 0 5px;
      margin-bottom: 10px;
      a{
        font-size: 16px;
      }
    }
    a {
      color: #a9b0b4;
      font-weight: 600;
      &:hover, &.active {
        color: $primary-color;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
