.box1 {
  .row {
    .slick-prev {
      bottom: 0;
      left:35%;
      top: 95%;
      background: url(../img/arrow_left.png) center center no-repeat;
      background-size: 100% 100%;
      width: 30px;
      height: 15px;
      &:before {
        content: none;
      }
    }
    .slick-next {
      bottom: 0;
      right:35%;
      top: 95%;
      background: url(../img/arrow_right.png) center center no-repeat;
      background-size: 100% 100%;
      width: 30px;
      height: 15px;
      &:before {
        content: none;
      }
    }
  }
  .box-item {
    @extend .col-md-4;
    @extend .col-sm-4;

    margin-bottom: 45px;
    text-align: center;
    .image-wrapper {
      margin-bottom: 25px;
      &:hover {
        opacity: 0.8;
      }
    }
    .box-title {
      @extend .underline;

      margin-bottom: 35px;
      font-size: $box1-title-fz;
      line-height: $box1-title-lh;
      font-weight: 700;
      a {
        color:$primary-color;
        &:hover {
          color: $secondary-color;
        }
      }
      @include break(400px) {
        font-size: 18px;
      }
    }
    .box-text {
      @extend .col-md-10;
      @extend .col-md-offset-1;
      color: lighten(#333c41, 20%);
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
      &:hover {
        color: #333c41;
        cursor:pointer
      }
    }

  }
}
