.welcome {
  padding-bottom: 82px;
  padding-top: 70px;
  position: relative;
  overflow: hidden;
  .title {
    font-size: $welcome-title-fz;
    line-height: $welcome-title-lh;
    color: $primary-color;
    margin-bottom: 40px;

    @include break(400px) {
      font-size: 28px;
      margin-bottom: 35px;
      line-height: 36px;
      b:first-child:before {
        content: '\a';
        white-space: pre;
      }
    }
  }
  .text {
    @extend .col-md-10;
    @extend .col-md-offset-1;
    p {
      color:$primary-color ;
      text-align: center;
      font-weight: 300;
      margin-bottom: 0;
    }
    a {
      text-decoration: underline;
      font-weight: 700;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &:before {
    content: '';
    background: url('../img/welcome_element.png') no-repeat;
    position: absolute;
    bottom: -10px;
    width: 250px;
    height: 250px;
    right: -2px;
    @include break(400px) {
      content: none;
    }
  }
  @include phone() {
    padding-top: 40px;
  }
}
