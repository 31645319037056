.form-control {
  background-color: $white;
  border: 1px solid #5998bf;
  outline: 0;
  box-shadow: none !important;
  color: #00305b;
  border-radius: 0;
  font-weight: bold;
  font-size: 17px;
  &:focus {
    border: 1px solid #5998bf;
    outline: 0;
  }
}

form#feedback [class*=col-] {
  background-clip: padding-box;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}
.feedback {
  color: #3294d1;
  b {
    font-size: 28px;
  }
  margin-bottom: 30px;
}
form#feedback {
  padding-bottom: 60px;
  label {
    color: #5998bf;
  }
}

#googleMap {
  padding-top: 0;
  padding-bottom: 0;
  iframe {
    width: 100%;
    border: 0;
    height: 500px;
    @include phone() {
      height: 280px; 
    }
  }
}

.message, .message-error {
  padding: 20px;
  text-align: center;
  background-color: #fff;
  color: #333;
  margin-bottom: 30px;
}



.bootstrap-datetimepicker-widget {
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}
