.box2 {
  .row {
    padding: 0 15px;

    .slick-prev {
      bottom: 0;
      left:35%;
      top: 95%;
      background: url(../img/arrow_left.png) center center no-repeat;
      background-size: 100% 100%;
      width: 30px;
      height: 15px;
      &:before {
        content: none;
      }
    }
    .slick-next {
      bottom: 0;
      right:35%;
      top: 95%;
      background: url(../img/arrow_right.png) center center no-repeat;
      background-size: 100% 100%;
      width: 30px;
      height: 15px;
      &:before {
        content: none;
      }
    }
  }
  .box-item {
    @include make-md-column(4,1px);
    @include make-sm-column(4,1px);

    color: lighten($dark, 20%);
    margin-bottom: 45px;

    .image-wrapper {
      margin-bottom: 35px;
      &:hover {
        opacity: 0.8;
      }
    }
    .content-wrapper {
      padding: 0 22px;
      position: relative;

      &:hover {
        .box-title a{
          color: $secondary-color;
        }
        .box-text {
          color: #333c41;
        }
      }
    }
    .short-size {
      width: 69%;
      @include break(400px) {
        width: 100%;
      }
    }
    .category-link {
      margin-left: 30px;
      margin-bottom: 10px;
      a {
        color:#a9b0b4;
        font-size: $caterory-link-fz;
        text-transform: capitalize;
        font-weight: 300;
        &:hover {
          color:lighten($dark, 10%);
        }
      }
      @include break(400px) {
        margin-left: 0;
      }
    }
    .box-title {
      font-size: $box2-title-fz;
      line-height: $box2-title-lh;
      font-weight: 700;
      margin-bottom: 20px;
      a {
        color: $primary-color;
      }
    }
    .box-date {
      position: absolute;
      top:-85px;
      right:15px;
      width:100px;
      height: 100px;
      padding: 20px;
      background: rgba($primary-color, 0.9);
      color: $white;
      font-size: $small-date-fz;
      text-align: center;
      font-weight: 300;
      .large-date {
        font-size: $large-date-fz;
        line-height: $large-date-lh;
        font-weight: 700;
      }
      @include break(400px) {
        top:-120px;
      }
    }
    .box-text {
      margin-bottom: 35px;
      color: lighten(#333c41, 20%);
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
    }
    .see-more {
      padding-left: 22px;
      color: $secondary-color;
      font-size: $see-more-fz;
      position: relative;
      font-weight: 300;
      &:hover {
        color: $primary-color;
        &:before {
          background: $primary-color;
        }
      }
      &:before {
        content: '';
        position: absolute;
        background: rgba($secondary-color, 0.5);
        height: 1px;
        width: 130px;
        margin-left: -20px;
        bottom: -2px;
      }
    }
  }
  @include break(400px) {
    text-align: center;
    .see-more {
      display: none;
    }
  }
}
