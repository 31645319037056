@media (min-width: 767px) and (max-width: 991px) {
  .fixedMenuLarge div.languages-xl {
    right: 70px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .collapse-minus, .collapse-plus {
    right: 35px;
    top: 20px;
  }
  .cd-primary-nav {
    padding-top: 70px;
    ul.fullscreenmenu li {
      padding: 10px 0;
      a {
        font-size: 30px;
        font-weight: 300;
      }
      ul {
        margin-top: 15px;
        margin-bottom: 0;
        li {
          padding: 10px 0;
          border-bottom: 0;
          a {
            font-size: 18px;
          }
        }
      }
    }
  }
  .fixedMenuLarge div.languages-xl {
    right: 80px;
  }
}

@media (min-width: 1200px) {
  .cd-primary-nav {
    padding-top: 80px;
    ul.fullscreenmenu li {
      padding: 15px 0;
      a {
        font-size: 44px;
        font-weight: normal;
      }
      ul {
        margin-top: 15px;
        margin-bottom: 0;
        li {
          padding: 10px 0;
          border-bottom: 0;
          a {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-height: 500px) {
  .cd-primary-nav {
    padding-top: 50px;
    ul.fullscreenmenu li ul {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  section#welcome {
    padding-bottom: 50px;
    h1 {
      padding-top: 40px;
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 20px;
    }
    .index-img {
      height: 200px;
    }
  }
}

@media (max-width: 600px) {
  section#welcome .index-img {
    height: 100px;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  section#welcome .index-img {
    height: 250px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  section#welcome .index-img {
    height: 300px;
  }
}

@media (min-width: 1200px) {
  section#welcome .index-img {
    height: 400px;
  }
}

@media (min-width: 1400px) {
  section#welcome .index-img {
    height: 450px;
  }
}

@media (min-width: 1600px) {
  section#welcome .index-img {
    height: 500px;
  }
}

@media (max-width: 767px) {
  #kareta1 {
    background: #efefef;
    padding-bottom: 15px;
    > h1 {
      padding: 25px 30px 35px;
      font-size: 35px;
      line-height: 42px;
      &:before {
        background: url(../img/arrow-h1-xs.png) center center no-repeat;
      }
    }
    .kareta1-item {
      margin-bottom: 20px;
      padding: 0 0 20px;
      .img-container {
        border: 20px solid #fff;
      }
      .text-box-container {
        h1 {
          font-size: 27px;
          line-height: 32px;
        }
        p {
          padding: 0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #kareta2 {
    > h1 {
      padding: 5px 30px 15px;
      font-size: 35px;
      line-height: 42px;
    }
    .kareta2-item-container {
      width: 100%;
      .text-box-container {
        padding: 10px 15px;
        &:before {
          clear: both;
        }
        div {
          &.kareta2-date {
            font-size: 20px;
            padding-bottom: 10px;
          }
          &.kareta2-price {
            font-size: 20px;
            padding-top: 20px;
          }
        }
      }
      &:nth-of-type(6n+4) {
        float: right;
        .img-container-left {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .text-box-container {
          position: absolute;
          bottom: 0;
          right: 0;
          height: 50%;
          background-color: rgba(14, 29, 46, 0.7);
          .kareta2-triangle {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 20px 20px 0;
            border-color: transparent rgba(14, 29, 46, 0.7) transparent transparent;
            position: absolute;
            left: -20px;
            top: 50%;
            margin-top: -20px;
            transition: border 0.3s ease;
          }
          &:hover {
            background-color: rgba(0, 11, 23, 0.8);
            .kareta2-triangle {
              border-color: transparent rgba(0, 11, 23, 0.8) transparent transparent;
            }
          }
        }
      }
      &:nth-of-type(6n+5) {
        float: left;
        .img-container-left {
          float: left;
          width: 50%;
          img {
            width: 100%;
          }
        }
        .text-box-container {
          left: auto;
          right: 0;
          height: 100%;
          background-color: #0056a4;
          .kareta2-triangle {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 20px 20px 0;
            border-color: transparent #0056a4 transparent transparent;
            position: absolute;
            left: -20px;
            top: 50%;
            margin-top: -20px;
            transition: border 0.3s ease;
          }
          &:hover {
            background-color: #003f78;
            .kareta2-triangle {
              border-color: transparent #003f78 transparent transparent;
            }
          }
        }
      }
    }
  }
  .filter-years-container {
    padding: 20px 0;
    font-size: 24px;
    line-height: 37px;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  #kareta2 .kareta2-item-container .text-box-container {
    padding: 10px 15px;
    div.kareta2-date {
      font-size: 24px;
      padding-bottom: 15px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #kareta2 .kareta2-item-container .text-box-container {
    padding: 10px 25px;
  }
}

@media (max-width: 767px) {
  footer div#bottomFooter {
    text-align: center;
    address {
      font-size: 18px;
      line-height: 28px;
    }
  }
}

@media (max-width: 767px) {
  .currentPageText h1 {
    padding: 25px 30px 35px;
    font-size: 35px;
    line-height: 42px;
    &:before {
      background: url(../img/arrow-h1-xs.png) center center no-repeat;
    }
  }
}

@media (max-width: 767px) {
  .arrow-nav {
    background: 0 0;
    a {
      font-size: 17px;
    }
    .arrow-left-container a {
      padding-left: 28px;
    }
    .arrow-right-container a {
      padding-right: 28px;
    }
  }
  .page-content-btn, .page-content-btn-1 {
    margin-top: 40px;
  }
  .page-content-date, .page-content-price {
    padding-top: 20px !important;
  }
  article#pageContent {
    padding-bottom: 30px;
    h1 {
      font-size: 35px;
      line-height: 42px;
    }
    div {
      &.page-content-date {
        width: 280px;
        font-size: 18px;
      }
      &.page-content-price {
        font-size: 26px;
      }
    }
  }
}
@media (max-width: 767px) {
  section#gallery .kareta-gal-item {
    padding: 0 17px;
    margin-bottom: 34px;
    a {
      span {
        font-size: 28px;
        line-height: 35px;
        text-shadow: 0 0 3px rgba(0, 1, 1, 0.7);
        padding: 15px;
      }
      img {
        width: 100%;
      }
    }
    span.triangle-gal {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 13px 13px;
      border-color: transparent transparent #fff;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
@media (max-width: 767px) {
  div.feedback h2 {
    font-size: 30px;
    line-height: 36px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #content-reservation {
    padding: 0 50px;
  }
}

@media (min-width: 1200px) {
  #content-reservation {
    padding: 0 90px;
  }
}

@media (max-width: 767px) {
  section#slider #top {
    padding-top: 50px;
  }
  .innerbanner {
    margin-top: 50px;
  }

  .tp-caption {
    min-width: 300px !important;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  section#slider {
    h1 {
      font-size: 34px;
      line-height: 40px;
    }
    h2 {
      font-size: 30px;
      line-height: 38px;
    }
  }
  .tp-caption {
    min-width: 600px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  section#slider {
    h1 {
      font-size: 40px;
      line-height: 48px;
    }
    h2 {
      font-size: 36px;
      line-height: 46px;
    }
  }
  .tp-caption {
    min-width: 700px !important;
  }
}

@media (min-width: 1200px) {
  .tp-caption {
    min-width: 800px !important;
  }
}
