@mixin absolutecenter($axis: "both"){
  position:absolute;
  @if $axis == "y"{
    top: 50%;
    transform: translateY(-50%);
  }
  @if $axis == "x"{
    left: 50%;
    transform: translateX(-50%);
  }
  @if $axis == "both"{
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
}

@mixin break($breakpoint) {
     @media only screen and(max-width:$breakpoint){
        @content;
    }
}
@mixin phone() {
     @media only screen and(max-width:400px){
        @content;
    }
}

@mixin sticky() {
  .fixedMenuLarge {
    background: rgba($dark-bg-color, 0.9);
    height: 50px;
  }
  .contact-us {
    top:7px;
  }
  .menu-button {
    top:0;
  }
  .logo-fixed {
    display: none;
  }
  .logo-sticky {
    display: block;
  }
}
